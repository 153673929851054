@import url('https://fonts.googleapis.com/css?family=Yellowtail');
@import url('https://fonts.googleapis.com/css?family=Archivo Black');

.App {
  text-align: center;
  background-color: #000;
  height: 100vh;
}

.Timer {
  background-color: #000;
  font-size: calc(10px + 8vmin);
  color: white;
  padding-top: 12vmin;
}

.App-link {
  color: #61dafb;
}

.Countdown {
  font-weight: 800;
  line-height: 115%;
}

.Number {
  display: inline-block;
  color: white;
}

.Unit {
  display: inline-block;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  color: black;
  margin-left: calc(10px + 0.5vmin);
}

.Label {
  color: white;
  position: relative;
  font-weight: 100;
  font-size: calc(10px + 3.9vmin);
}

a {
  color: white;
  text-decoration-thickness: 0.2vmin;
  text-underline-offset: 0.3vmin;
}

.Settings {
  background-color: #000;
  font-size: calc(10px + 8vmin);
  color: white;
  font-weight: 100;
  max-width: 600px;
  width: 80vw;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);

  border-color: #FFF;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;

  padding: 0px;
}

.SettingsLabel {
  font-size: calc(10px + 3vmin);
  text-align: left;
  margin: 0px;
  margin-left: 20px;
  top: -20px;
  background-color: #000;
  position: relative;
  width: min-content;
}

.Question {
  margin: 0px;
}

.Option {
  top: -15px;
  position: relative;
  margin: 0px;
  padding: 0px;
}

.SelectedOption {
  top: -15px;
  position: relative;
  margin: 0px;
  padding: 0px;
  font-weight: 400;
}

@media (max-width:800px) {
  .Timer {
    padding-top: 20vmin;
  }
}